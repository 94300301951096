<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoIndicadores"
      :loading="loading"
      :paginate="true"
      :search="search"
      sort-by="tipo_indicador"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Indicadores</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="1601"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />

          <FiltroIndicadores
            @selectedFilters="fetchIndicadores((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.ativa_campanha`]="{ item }">
        <v-chip
          :color="formatStatus(item.ativa_campanha) === 'Sim' ? 'green' : 'red'"
          dark
        >
          {{ formatStatus(item.ativa_campanha) }}
        </v-chip>
      </template>
      <template v-slot:[`item.gmr_campanha`]="{ item }">
        <v-chip
          :color="formatStatus(item.gmr_campanha) === 'Sim' ? 'green' : 'red'"
          dark
        >
          {{ formatStatus(item.gmr_campanha) }}
        </v-chip>
      </template>

      <template v-slot:[`item.tipo_indicador`]="{ item }">
        {{ item.tipo_indicador | TitleCase }}
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          icon
          v-can-access="1602"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          icon
          v-can-access="1603"
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom
          :name="'edit'"
          v-can-access="1602"
          @click="editItem(item)"
        />
        <IconBottom
          :name="'delete'"
          v-can-access="1603"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalCadIndicadores
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editIndicador="indicador"
      @close="refresh()"
    />
  </div>
</template>

<script>
import FiltroIndicadores from "./FiltroIndicadores";
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable";
import sistema from "@/services/http/sistemaService";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import ModalCadIndicadores from "@/components/parametros/indicadores/ModalCadIndicadores";

export default {
  name: "ListaIndicadores",

  components: {
    BaseTable,
    RegisterBottom,
    ModalCadIndicadores,
    FiltroIndicadores,
    IconBottom
  },

  data() {
    return {
      search: "",
      loading: false,
      filters: {},
      dialog: false,
      labelBtn: "Salvar",
      infoIndicadores: [],
      indicador: {},
      headers: [
        {
          text: "Codigo",
          value: "id_indicador"
        },
        {
          text: "Indicador",
          value: "indicador"
        },
        {
          text: "Tipo",
          value: "tipo_indicador"
        },
        {
          text: "Ativa Campanha",
          value: "ativa_campanha",
          align: "center"
        },
        {
          text: "GMR",
          value: "gmr_campanha",
          align: "center"
        },
        {
          text: "Ações",
          value: "acoes",
          align: "center"
        }
      ]
    };
  },

  methods: {
    async fetchIndicadores(filters) {
      this.loading = true;
      const { data } = await sistema()
        .indicadores()
        .show({
          per_page: -1,
          ativa_campanha: "S",
          ...filters
        });
      this.infoIndicadores = data.data;
      this.loading = false;
    },

    editItem(item) {
      this.indicador = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    refresh() {
      this.dialog = false;
      this.loading = true;
      this.fetchIndicadores(this.filters);
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "deletar indicador",
          `Deseja deletar o indicador ${item.indicador}?`
        );

        sistema()
          .indicadores(item.id_indicador)
          .delete(
            {},
            {
              notification: "success",
              menssage: "Indicador deletado"
            }
          );
        this.refresh();
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar indicador"
        });
      } finally {
        this.fetchIndicadores(this.filters);
      }
    },

    formatStatus(item) {
      if (item == "S") item = "Sim";
      if (item == "N") item = "Não";
      return item;
    }
  },

  mounted() {
    this.fetchIndicadores();
  }
};
</script>
