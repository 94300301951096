var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.infoIndicadores,"loading":_vm.loading,"paginate":true,"search":_vm.search,"sort-by":"tipo_indicador"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de Indicadores")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(1601),expression:"1601"}],on:{"click":function($event){(_vm.dialog = true), (_vm.labelBtn = 'Salvar')}}}),_c('FiltroIndicadores',{on:{"selectedFilters":function($event){_vm.fetchIndicadores((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.ativa_campanha",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.formatStatus(item.ativa_campanha) === 'Sim' ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(_vm.formatStatus(item.ativa_campanha))+" ")])]}},{key:"item.gmr_campanha",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.formatStatus(item.gmr_campanha) === 'Sim' ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(_vm.formatStatus(item.gmr_campanha))+" ")])]}},{key:"item.tipo_indicador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.tipo_indicador))+" ")]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(1602),expression:"1602"}],attrs:{"name":'edit'},on:{"click":function($event){return _vm.editItem(item)}}}),_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(1603),expression:"1603"}],attrs:{"name":'delete'},on:{"click":function($event){return _vm.deleteItem(item)}}})]}}],null,true)}),_c('ModalCadIndicadores',{attrs:{"dialog":_vm.dialog,"labelBtn":_vm.labelBtn,"editIndicador":_vm.indicador},on:{"close":function($event){return _vm.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }