<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>
    <v-card class="pa-0">
      <v-switch
        v-model="filters.ativa_campanha"
        true-value="S"
        false-value="N"
        :label="
          `${
            filters.ativa_campanha == 'S'
              ? 'Ativa Campanha'
              : 'Não Ativa Campanha'
          }`
        "
        @change="fetchIndicadores()"
      ></v-switch>
      <v-container>
        <BaseFilter
          label="Indicador"
          service="sistemaService.indicadores"
          v-model="filters.id_indicador"
          item-text="indicador"
          item-value="id_indicador"
          prepend-inner-icon="mdi-finance"
          clearable
          chips
          single-line
          :hasCode="true"
          :multiple="false"
          @change="fetchIndicadores()"
        />
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import FilterBottom from "@/components/shared/bottons/FilterBottom";
import BaseFilter from "@/components/shared/BaseSelectService";
export default {
  name: "FiltroIndicadores",
  components: {
    FilterBottom,
    BaseFilter
  },

  data() {
    return {
      filters: { ativa_campanha: "S" },
      indicadores: {
        items: [],
        loading: false,
        selected: ""
      }
    };
  },
  methods: {
    fetchIndicadores() {
      this.$emit("selectedFilters", this.filters);
    }
  }
};
</script>
