<template>
  <v-container class="container-card">
    <v-card>
      <ListaIndicadores />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ListaIndicadores from "@/components/parametros/indicadores/ListaIndicadores";

export default {
  name: "ConsultaIndicadores",

  components: {
    ListaIndicadores
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consulta Indicador");
  }
};
</script>

<style scoped></style>
