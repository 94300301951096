<template>
  <v-dialog v-model="dialog" persistent max-width="30%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar" ? "Editar Indicador" : "Cadastrar Indicador"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                label="Codigo"
                v-model="formIndicador.id_indicador"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <v-text-field
                label="Indicador"
                v-model="formIndicador.indicador"
                :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col lg="4" md="4" sm="12" cols="12">
              <BaseSelect
                clearable
                single-line
                label="Tipo"
                item-text="tipo_indicador"
                item-value="id_tipo_indicador"
                :rules="[rules.required]"
                service="sistemaService.tiposIndicadores"
                v-model="formIndicador.id_tipo_indicador"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                label="Base GMR"
                :items="[
                  { text: 'Sim', value: 'S' },
                  { text: 'Não', value: 'N' }
                ]"
                chips
                v-model="formIndicador.gmr_campanha"
                item-text="text"
                item-value="value"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" sm="4" md="4">
              <v-autocomplete
                label="Ativa Campanha"
                :items="[
                  { text: 'Sim', value: 'S' },
                  { text: 'Não', value: 'N' }
                ]"
                chips
                :rules="[rules.required]"
                v-model="formIndicador.ativa_campanha"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="
            labelBtn != 'Salvar' ? editarIndicador() : cadastrarIndicador()
          "
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import sistema from "@/services/http/sistemaService";
import BaseSelect from "@/components/shared/BaseSelectService";
export default {
  name: "ModalCadIndicador",

  mixins: [rules],
  components: {
    BaseSelect
  },

  props: {
    dialog: {
      type: Boolean
    },

    labelBtn: {
      type: String
    },

    editIndicador: {
      type: Object
    }
  },

  data() {
    return {
      formIndicador: {},
      items: [
        {
          ativa_campanha: "Ativo",
          value: "S"
        },
        {
          ativa_campanha: "Inativo",
          value: "N"
        },
        {
          gmr_campanha: "Ativo",
          value: "S"
        },
        {
          gmr_campanha: "Inativo",
          value: "N"
        }
      ]
    };
  },

  watch: {
    editIndicador() {
      if (this.editIndicador) {
        this.formIndicador = this.editIndicador;
      }
    }
  },

  methods: {
    async cadastrarIndicador() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await sistema()
          .indicadores()
          .store(this.formIndicador, {
            notification: true,
            message: "Indicador cadastrado com sucesso!"
          }),
          this.close();
      }
    },

    async editarIndicador() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await sistema()
          .indicadores(this.formIndicador.id_indicador)
          .update(this.formIndicador, {
            notification: true,
            message: "Indicador editado com sucesso!"
          }),
          this.$notify({
            type: "success",
            text: "Indicador editado"
          });

        this.close();
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
